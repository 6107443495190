<template>
  <div class="container">
    <img class="logo" src="@/assets/images/logo_eqafh_pic@2x.png" />
    <div class="item">
      <!-- <div class="icon">
        <img src="@/assets/images/person_sign_icon@2x.png" />
      </div> -->
      <div class="inputBox">
        <input
          type="text"
          v-model="UserName"
          placeholder="姓名（必填）"
        />
      </div>
    </div>
    <div class="item">
      <!-- <div class="icon">
        <img src="@/assets/images/hospital_sign_icon@2x.png" />
      </div> -->
      <div class="inputBox">
        <input
          type="text"
          v-model="HospitalKeyWords"
          placeholder="请输入您所在医院关键字搜索（必填）"
        />
        <img class="searchIcon" src="@/assets/images/search_register@2x.png" />
      </div>
      <div class="HospitalList" v-show="showHospitalPicker">
        <span v-for="(item, index) in HospitalColumns" :key="index" @click="selectHospital(item.HospitalName, item.HospitalCode)">{{item.HospitalName}}</span>
      </div>
    </div>
    <!-- <van-popup v-model="showHospitalPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="HospitalColumns"
        value-key="HospitalName"
        @cancel="showHospitalPicker = false"
        @confirm="onHospitalConfirm"
      />
    </van-popup> -->
    <div class="notFoundHospital" v-show="!notFoundHospital">
      <img
        :src="
          !notFoundHospital
            ? require('@/assets/images/radio@2x.png')
            : require('@/assets/images/dis_system_complete_icon@2x.png')
        "
      />
      <span class="declareText">未找到您所在的医院？</span>
    </div>
    <div class="item" v-show="!notFoundHospital">
      <div class="inputBox">
        <input type="text" v-model="HospitalName" placeholder="请输入您所在的医院全称（必填）" />
      </div>
    </div>
    <div class="item">
      <!-- <div class="icon">
        <img src="@/assets/images/title_sign_icon@2x.png" />
      </div> -->
      <div class="inputBox" @click="showPositionPicker = true">
        <div class="selectInput">
          <input
            type="text"
            v-model="PositionName"
            placeholder="请选择职称（必填）"
          />
          <div></div>
        </div>
        <img class="pulldownIcon" src="@/assets/images/triangle@2x.png" />
      </div>
    </div>
    <van-popup v-model="showPositionPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="PositionColumns"
        value-key="Titles"
        @cancel="showPositionPicker = false"
        @confirm="onPositionConfirm"
      />
    </van-popup>
    <div class="item">
      <!-- <div class="icon">
        <img src="@/assets/images/department_sign_icon@2x.png" />
      </div> -->
      <div class="inputBox" @click="showDepartment">
        <div class="selectInput">
          <input
            style="width: 100%;"
            type="text"
            v-model="DepartmentName"
            placeholder="请选择科室/亚术式（必填）"
          />
          <div></div>
        </div>
        <img class="pulldownIcon" src="@/assets/images/triangle@2x.png" />
      </div>
    </div>
    <div class="department_content" v-if="showOperation">
      <div v-for="(item, index) in OperationColumns" :key="index" :class="{'operationBox': item.isChecked}">
        <span class="operationItem" v-if="item.isChecked">{{item.Name}}</span>
      </div>
    </div>
    <van-popup v-model="showDepartmentPicker" round position="bottom" :style="{ height: '50%' }">
      <div class="pickerContent">
        <div class="van-picker__toolbar toolbar">
          <button type="button" class="van-picker__cancel" @click="onDepartmentCancel">取消</button>
          <button type="button" class="van-picker__confirm" @click="onDepartmentConfirm">确认</button>
        </div>
        <div class="picker_content">
          <div class="pickerBox" v-for="(department, index) in DepartmentColumns" :key="index">
            <div class="pickerItemBox">
              <div class="picker_block" :class="{'active': tempDepartmentCode == item.code}" v-for="(item, sonIndex) in department" :key="sonIndex" @click="selectDepartment(item.code, item.Name, index, sonIndex)">{{item.Name}}</div>
            </div>
            <div class="pickerSonItemContent" v-show="tempOperationColumns.length > 0 && tempDepartmentIndex === index">
              <p>请选择和您相关的亚术式：</p>
              <div class="pickerSonItemBox">
                <div class="triangle" :style="{'left': leftRange}"></div>
                <div class="pickerSon_block" :class="{'activeBg': item.isChecked}" v-for="(item, index) in tempOperationColumns" :key="index" @click="selectOperation(index)">{{item.Name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <div class="item">
      <!-- <div class="icon">
        <img class="iphoneIcon" src="@/assets/images/iphone_sign_icon@2x.png" />
      </div> -->
      <div class="inputBox">
        <input
          type="number"
          v-model="PhoneNumber"
          placeholder="请输入手机号码（必填）"
        />
      </div>
    </div>
    <div class="item">
      <!-- <div class="icon">
        <img src="@/assets/images/yzm_sign_icon@2x.png" />
      </div> -->
      <div class="codeBox">
        <input type="text" v-model="Code" maxlength="6" placeholder="请输入短信验证码（必填）" />
        <span
          class="sendCode"
          v-if="!alreadySend"
          @click="sendCode"
          :style="{ color: PhoneNumber ? '#C70C3C' : '#CCCCCC' }"
          >{{isSendCode ? '重新获取' : '获取验证码'}}</span
        >
        <span class="sendCode" style="color: #C70C3C" v-else>{{
          second + "S"
        }}</span>
      </div>
    </div>
    <div class="declare" @click="checkedDeclare">
      <img
        :src="
          isCheckedDeclare
            ? require('@/assets/images/system-complete@2x.png')
            : require('@/assets/images/dis_system_complete_icon@2x.png')
        "
      />
      <span class="declareText">我同意<span class="highlight" @click="$router.push('/termsOfUse')">《使用条款》</span>和<span class="highlight" @click="$router.push('/privacyPolicy')">《隐私政策》</span></span>
    </div>
    <button class="register" @click="register">注册</button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import storage from "../../common/utils/storage";

const delay = (function() {
 let timer = 0;
 return function(callback, ms) {
  clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export default {
  name: "Register",
  data() {
    return {
      UserName: '', // 用户姓名
      HospitalCode: 0, // 医院Code
      HospitalName: '', // 医院
      HospitalKeyWords: '', // 医院搜索关键字
      showHospitalPicker: false,
      HospitalColumns: [], // 医院选择项
      SelectHospital: "",
      notFoundHospital: true, // 是否找到医院
      PositionId: 0, // 职称ID
      PositionName: '', // 职称
      showPositionPicker: false,
      PositionColumns: [], // 职称选择项
      DepartmentColumns: [], // 科室选择项
      tempDepartmentIndex: '',
      DepartmentIndex: '',
      tempDepartmentCode: '', // 临时选中科室Code
      tempDepartmentName: '', // 临时选中科室Name
      DepartmentCode: '', // 选中科室Code
      DepartmentName: '', // 选中科室Name
      OperationColumns: [], // 亚术式选项
      tempOperationColumns: [],
      showOperation: false,
      showDepartmentPicker: false,
      PhoneNumber: "", // 手机号数
      Code: "", // 验证码
      isCheckedDeclare: false, // 是否同意用户协议
      second: 60,
      alreadySend: false, // 是否发送验证码
      isSendCode: false,
      leftRange: '17%',
      isLock: true,
      validateNum: 0, // 验证验证码次数
    };
  },
  computed: {
    ...mapState(['Openid', 'userInfo']),
    sid() {
      return this.$route.query.sid;
    },
    uri() {
      return this.$route.query.uri;
    }
  },
  watch: {
    HospitalKeyWords(newValue) {
      if (newValue == this.SelectHospital) {
        this.showHospitalPicker = false;
      } else {
        delay(() => {
          this.GetHospital();
        }, 300);
      }
    }
  },
  created() {
    this.getPosition();
    this.getDepartment();

    if(!this.userInfo) {
      storage.setStore('scope', 'snsapi_userinfo');
      this.$router.push({
        path: '/authorize',
        query: {scope: 'snsapi_userinfo'}
      })
    }
  },
  methods: {
    async GetHospital() {
      let res = await this.$axios.post('/Api/Api/Web/GetWxUser/GetHospital?HospitalName=' + this.HospitalKeyWords);
      let data = res.Data;
      if (data.length > 0) {
        this.showHospitalPicker = true;
        this.HospitalColumns = data;
      } else {
        this.showHospitalPicker = false;
        this.HospitalColumns = [];
      }

      if (this.HospitalKeyWords) {
        if (data.length == 0) {
          this.notFoundHospital = false;
        } else {
          this.notFoundHospital = true;
        }
      } else {
        this.showHospitalPicker = false;
        this.notFoundHospital = true;
      }
    },
    selectHospital(HospitalName, HospitalCode) {
      this.HospitalCode = HospitalCode;
      this.HospitalKeyWords = HospitalName;
      this.SelectHospital = HospitalName;
      this.showHospitalPicker = false;
    },
    // 查询医院
    // searchHospital() {
    //   if (this.HospitalKeyWords) {
    //     this.$axios.post('/Api/Api/Web/GetWxUser/GetHospital?HospitalName=' + this.HospitalKeyWords).then(res => {
    //       this.HospitalId = 0;
    //       this.HospitalName = '';
    //       let data = res.Data;
    //       if (data.length > 0) {
    //         this.showHospitalPicker = true;
    //         this.notFoundHospital = true;
    //         this.HospitalColumns = data;
    //       } else {
    //         this.showHospitalPicker = false;
    //         this.notFoundHospital = false;
    //       }
    //     })
    //   } else {
    //     this.$toast('搜索内容不能为空！');
    //   }
    // },
    // 选择医院
    // onHospitalConfirm(value) {
    //   this.HospitalId = value.HospitalID;
    //   this.HospitalKeyWords = value.HospitalName;
    //   this.showHospitalPicker = false;
    // },
    // 获取职称选项
    getPosition() {
      this.$axios.get('/Api/Api/Web/GetWxUser/GetTitle').then(res => {
        this.PositionColumns = res.Data;
      })
    },
    // 选择职称
    onPositionConfirm(value) {
      this.PositionId = value.Id;
      this.PositionName = value.Titles;
      this.showPositionPicker = false;
    },
    // 获取科室
    getDepartment() {
      this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=1&pcode=').then(res => {
        let data = res.Data;
        let result = [];
        for(let i = 0; i < data.length; i+=3) {
          result.push(data.slice(i, i+3));
        }
        this.DepartmentColumns = result;
      })
    },
    // 显示科室选中picker框
    showDepartment() {
      this.showDepartmentPicker =  true;
      this.tempDepartmentCode = this.DepartmentCode;
      this.tempDepartmentName = this.DepartmentName;
      this.tempDepartmentIndex = this.DepartmentIndex;
      this.tempOperationColumns = JSON.parse(JSON.stringify(this.OperationColumns));
    },
    // 取消选择科室
    onDepartmentCancel() {
      this.showDepartmentPicker = false;
      this.tempDepartmentCode = '';
      this.tempDepartmentName = '';
      this.tempDepartmentIndex = '';
      this.tempOperationColumns = [];
      this.isShowOperation();
    },
    // 确认选择科室
    onDepartmentConfirm() {
      this.showDepartmentPicker = false;
      this.DepartmentCode = this.tempDepartmentCode;
      this.DepartmentName = this.tempDepartmentName;
      this.DepartmentIndex = this.tempDepartmentIndex;
      this.OperationColumns = JSON.parse(JSON.stringify(this.tempOperationColumns));
      this.isShowOperation();
    },
    // 选择科室
    selectDepartment(code, name, index, sonIndex) {
      this.tempDepartmentCode = code;
      this.tempDepartmentName = name;
      this.tempDepartmentIndex = index;
      this.$axios.post('/Api/Api/Web/GetWxUser/GetDitWebCode?type=2&pcode=' + this.tempDepartmentCode).then(res => {
        this.tempOperationColumns = res.Data;
      })

      switch(sonIndex) {
        case 0:
          this.leftRange = '16%';
          break;
        case 1:
          this.leftRange = '48%';
          break;
        case 2:
          this.leftRange = '80%';
          break
      }
    },
    // 选择亚术式
    selectOperation(index) {
      this.tempOperationColumns[index].isChecked = !this.tempOperationColumns[index].isChecked;
      this.$forceUpdate();
    },
    // 是否显示选中的亚术式
    isShowOperation() {
      this.showOperation = this.OperationColumns.some(item => {
        if (item.isChecked) return true
      })
    },
    // 发送验证码
    sendCode() {
      let errMsg = "";
      if (this.PhoneNumber) {
        let mobile_regex = /^(?:(?:\+|00)86)?1\d{10}$/;
        if (!mobile_regex.test(this.PhoneNumber)) {
          errMsg = "手机号码格式错误！";
        }
      } else {
        errMsg = "请输入手机号码！";
      }

      if (errMsg == "") {
        this.timer();
        this.$axios.get('/Api/Api/Web/GetWxUser/GetVCode', {
          params: {
            Phone: this.PhoneNumber,
            Type: 1
          }
        }).then((res) => {
          if (res.RetCode == '10000') {
            this.validateNum = 0;
          }
        })
      } else {
        this.$toast(errMsg);
      }
    },
    // 验证码计时
    timer() {
      this.isSendCode = true;
      this.alreadySend = true;
      let promise = new Promise((resolve) => {
        let setTimer = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.alreadySend = false;
            resolve(setTimer);
          }
        }, 1000);
      });
      promise.then((setTimer) => {
        clearInterval(setTimer);
      });
    },
    // 是否同意用户协议
    checkedDeclare() {
      this.isCheckedDeclare = !this.isCheckedDeclare;
    },
    // 注册
    register() {
      if (!this.UserName) {
        this.$toast('请输入姓名！');
        return false;
      } else {
        if (this.UserName.length < 2) {
          this.$toast('姓名不能少于2个字！');
          return false;
        }
      }


      if (this.notFoundHospital) {
        if (!this.HospitalKeyWords) {
          this.$toast('请选择所在的医院！');
          return false;
        }
      } else {
        if (!this.HospitalName) {
          this.$toast('请输入所在的医院！');
          return false;
        }
      }

      if (!this.PositionName) {
        this.$toast('请选择职称！');
        return false;
      }

      if (!this.DepartmentName) {
        this.$toast('请选择科室！');
        return false;
      }

      if (!this.showOperation) {
        this.$toast('请选择亚术式！');
        return false;
      }

      if (!this.PhoneNumber) {
        this.$toast('请输入手机号码！');
        return false;
      }

      if (!this.Code) {
        this.$toast('请输入短信验证码！');
        return false;
      }

      if(!this.isCheckedDeclare) {
        this.$toast('请勾选《隐私声明》和《用户协议》并同意！');
        return false;
      }

      let CSList = [];
      this.OperationColumns.forEach(item => {
        if (item.isChecked) {
          CSList.push({
            SonSubjectCode: item.code,
            SonSubjectName: item.Name
          })
        }
      })


      let data = {
        OpenID: this.Openid,
        RealName: this.UserName,
        HCode: this.notFoundHospital ? this.HospitalCode : 0,
        HospitalTitle: this.notFoundHospital ? this.HospitalKeyWords : this.HospitalName,
        PositionalId: this.PositionId,
        PositionalName: this.PositionName,
        SubjectCode: this.DepartmentCode,
        SubjectName: this.DepartmentName,
        CSList: CSList,
        Phone: this.PhoneNumber,
        Vcode: this.Code
      }
      
      if(this.validateNum < 5) {
        if (this.isLock) {
          this.isLock = false;
          this.$axios.post('/Api/Api/Web/GetWxUser/UserRegister', data).then((res) => {
            console.log(res)
            if (res.RetCode == '10000') {
              this.isLock = true;
              storage.setStore('Token', res.Data.Token);

              let sid = sessionStorage.getItem("sid");
              let key = sessionStorage.getItem("key");
              // if (sid) {
              //   this.$axios.get('/Api/Api/web/SalesDoctor/AddSalesDoctor?Sid=' + sid + '&Did=' + res.Data.Id).then(res => {
              //     if (res.RetCode == '10000') {
              //       console.log('绑定成功！')
              //       this.$router.push('/interest');
              //     } else {
              //       this.$toast(res.RetMsg);
              //     }
              //   })
              // }
              // if (key) {
              //   this.$axios.get('/Api/Api/web/GetWxUser/UpdataCustmForKey?key=' + key + '&OpenID=' + this.Openid).then(res => {
              //     if (res.RetCode == '10000') {
              //       console.log('绑定成功！')
              //       this.$router.push('/interest');
              //     } else {
              //       this.$toast(res.RetMsg);
              //     }
              //   })
              // }
              this.$router.push('/interest');
            } else {
              this.isLock = true;
              this.$toast(res.RetMsg);
              if(res.RetMsg == '手机验证码错误！') {
                this.validateNum++;
              }
            }
          })
        }
      } else {
        this.$toast('请重新获取验证码！');
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  background: linear-gradient(to bottom, #fafafa 70%, rgba(202, 0, 27, 0.1) 100%);
  padding: 28px;
  box-sizing: border-box;
}
.logo {
  width: 150px;
  display: block;
  margin: 54px auto 28px;
}
.item {
  position: relative;
  display: flex;
  height: 55px;
  line-height: 55px;
  margin-bottom: 20px;
  border-radius: 55px;
  background-color: #F3F5F5;
  .icon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ffffff;
    img {
      width: 21px;
    }
    .iphoneIcon {
      width: 16px;
    }
  }
  .HospitalList {
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 9;
    width: 100%;
    max-height: 200px;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 8px 12px #ebedf0;
    overflow-y: scroll;
    span {
      padding: 5px 10px;
      display: block;
      font-size: 12px;
      line-height: 24px;
    }
    span:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }
  }
  .inputBox {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 16px;
    .selectInput {
      position: relative;
      flex: 1;
      div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    input {
      flex: 1;
      padding: 0 6px;
      border: none;
      box-sizing: border-box;
      background: transparent;
      color: #333333 !important;
    }
    // input:disabled, input[disabled]{
    //   color: #333333 !important;
    //   opacity: 1;
    //   -webkit-text-fill-color:#333333 !important; // ios 和 安卓9.0 必须添加此属性，才会生效
    //   -webkit-opacity:1;
    // }
    .searchIcon {
      width: 18px;
      height: 18px;
      padding: 0 8px;
    }
    .pulldownIcon {
      width: 16px;
      padding: 0 8px;
    }
  }
  .codeBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0 16px;
    input {
      width: 70%;
      padding: 0 6px;
      border: none;
      box-sizing: border-box;
      background: transparent;
      color: #333333 !important;
    }
    .sendCode {
      font-size: 15px;
      color: #cccccc;
    }
  }
}
.declare {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #666666;
  margin-top: 32px;
  img {
    width: 14px;
    height: 14px;
    margin: 0 5px 0 10px;
  }
  .highlight {
    color: #CA001B;
  }
}
.notFoundHospital {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #333333;
  margin: 20px 0;
  text-decoration: underline;
  img {
    width: 14px;
    height: 14px;
    margin: 0 5px 0 10px;
  }
}
.register {
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 16px;
  color: #ffffff;
  border: none;
  background-color: #C70C3C;
  border-radius: 55px;
  margin: 28px 0 0;
}
.picker_content {
  padding-top: 10px;
  .pickerBox {
    .pickerItemBox { 
      padding: 0 8px;
      display: flex;
      flex-wrap: wrap;
      .picker_block {
        width: calc(calc(100% / 3) - 12px);
        height: 36px;
        line-height: 36px;
        text-align: center;
        border: 1px solid rgba(153, 153, 153, 0.5);
        box-sizing: border-box;
        margin: 6px;
        border-radius: 4px;
        color: #333333;
      }
      .active {
        color: #FFFFFF;
        background-color: #C70C3C;
        border: none;
        box-sizing: border-box;
      }
    }
    .pickerSonItemContent {
      position: relative;
      background-color: #F8F8F8;
      padding: 8px;
      margin-top: 8px;
    }
    .pickerSonItemBox {
      // position: relative;
      display: flex;
      flex-wrap: wrap;
      background-color: #F8F8F8;
      margin: 8px 0;
      .pickerSon_block {
        width: calc(calc(100% / 3) - 12px);
        height: 36px;
        line-height: 36px;
        text-align: center;
        box-sizing: border-box;
        margin: 6px;
        border-radius: 4px;
        color: #333333;
        background-color: #FFFFFF;
      }
      .activeBg {
        color: #C70C3C;
        background-image: url('../../assets/images/select_system_btn@2x.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
      .triangle {
        width: 0px;
        height: 0px;
        border-top: 8px solid #FFFFFF;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        position: absolute;
        top: 0px;
        left: 15%;
      }
    }
  }
}
.pickerSon_content {
  width: 100%;
}
.department_content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .operationBox {
    width: calc(calc(100% / 4) - 12px);
    margin: 6px;
  }
  .operationItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    background-color: #FFFFFF;
    border-radius: 4px;
    color: #333333;
    font-size: 12px;
    padding: 0 5px;
    box-sizing: border-box;
  }
}
.pickerContent {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .picker_content {
    flex: 1;
    height: 0;
    overflow-y: scroll;
  }
}
</style>
